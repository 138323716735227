.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-right,
.rounded-top {
  border-top-right-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
}

.rounded-right {
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom,
.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  clear: both;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 480px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1024px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1920px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.clearfix::after,
.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-column,
.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-column-reverse,
.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 480px) {

  .flex-sm-column,
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-column-reverse,
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {

  .flex-md-column,
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-column-reverse,
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1024px) {

  .flex-lg-column,
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-column-reverse,
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1440px) {

  .flex-xl-column,
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-column-reverse,
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1920px) {

  .flex-xxl-column,
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xxl-column-reverse,
  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 480px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 1024px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1440px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1920px) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-bottom,
.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-top {
  top: 0;
}

.fixed-bottom {
  bottom: 0;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 480px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1920px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }

  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }

  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }

  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }

  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }

  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }

  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }

  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 480px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1920px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-auto,
.row>* {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1,
.col-2,
.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2,
.col-3 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4,
.col-5,
.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5,
.col-6 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7,
.col-8,
.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8,
.col-9 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10,
.col-11,
.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11,
.col-12 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 480px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1,
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3,
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5,
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7,
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-10,
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11,
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1,
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3,
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5,
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7,
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-10,
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11,
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1024px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1,
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3,
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5,
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7,
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-10,
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11,
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1440px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1,
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3,
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5,
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7,
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-10,
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11,
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1920px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1,
  .col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xxl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xxl-3,
  .col-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xxl-5,
  .col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7,
  .col-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xxl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xxl-10,
  .col-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xxl-11,
  .col-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xxl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xxl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xxl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xxl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xxl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xxl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xxl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xxl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xxl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xxl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xxl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xxl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xxl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xxl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xxl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
}

.carousel,
.carousel-inner {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  -o-transition: -o-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out,
    -o-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  -o-transform: none;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0s 0.6s opacity;
  -o-transition: 0s 0.6s opacity;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 1;
  -webkit-transition: opacity 0.15s ease;
  -o-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-next,
  .carousel-control-prev {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 1;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' stroke='rgba(0,0,0,.6)' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' stroke='rgba(0,0,0,.6)' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@font-face {
  font-family: NotoSans;
  src: url(../fonts/NotoSans-Regular.ttf);
}

@font-face {
  font-family: "OpenSans";
  src: url(../fonts/OpenSans.ttf) format("ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(../fonts/Poppins-Regular.ttf) format("ttf");
}

@font-face {
  font-family: "Sailec";
  src: url(../fonts/Sailec.otf) format("opentype");
}

@font-face {
  font-family: "SailecBold";
  src: url(../fonts/SailecBold.otf) format("opentype");
}

@font-face {
  font-family: "SailecLight";
  src: url(../fonts/SailecLight.otf) format("opentype");
}

.light-text {
  color: #fff;
}

.dark-text {
  color: #000;
}

.default-text-color {
  color: #161725;
}

.cranberry-text-color {
  color: #c6005d;
}

.purple-text-color {
  color: #560184;
}

.cyan-text-color {
  color: #3ab8f4;
}

#section-01 .text-section,
.center-text {
  text-align: center;
}

.strike-through {
  text-decoration: line-through;
}

.light-text-shadow {
  text-shadow: -2px 2px 0 #000, 2px 2px 0 #000, 2px -2px 0 #000,
    -2px -2px 0 #000;
}

a,
a:hover {
  text-decoration: none;
}

a {
  color: #c31f64;
  cursor: pointer;
}

a:hover {
  color: #6b2d8b;
}

a:focus {
  text-decoration: none;
  outline: 0;
}

.arrow-link::after {
  display: inline-block;
  -webkit-transform: scale(1, 1.5);
  -o-transform: scale(1, 1.5);
  transform: scale(1, 1.5);
  content: ">";
  position: relative;
  margin-left: 12px;
}

ol li,
p,
ul li {
  font-weight: inherit;
}

p {
  font-size: 24px;
  font-family: "SailecLight", "OpenSans", sans-serif;
  color: #000105;
  line-height: 44px;
  margin: 0 0 3.36rem;
}

@media (max-width: 479.98px) {
  p {
    font-size: 14px;
    line-height: 3rem;
  }
}

ul {
  margin: 0 0 0 3.5rem;
}

ol li,
ul li {
  font-size: inherit;
  font-family: inherit;
  line-height: 4.6rem;
  color: inherit;
}

ol {
  margin: 0 0 0 1.25rem;
}

ol li {
  display: list-item;
  list-style-position: inside;
  position: relative;
}

.no-bullets {
  margin: 0;
}

.no-bullets li::marker {
  content: "";
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 3.2rem 0;
  color: inherit;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
}

audio,
button,
fieldset,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
label,
menu,
nav,
p,
section,
table,
tbody,
td,
th,
tr,
video {
  margin: 0;
  border: 0;
}

// section { 
//   max-width: 1920px;
//   margin: 0 auto;
// }

.header-section,
.header-section .header-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-section {
  height: 80vh;
  background-image: url(../images/HeroBG.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center right;
}

@media (max-width: 1023.98px) {
  .header-section {
    height: 66vh;
    background-size: cover;
  }
}

@media (max-width: 767.98px) {
  .header-section {
    height: unset;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.header-section .header-left {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
  color: #fff;
}

@media (max-width: 767.98px) {
  .header-section .header-left {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1439.98px) {
  .header-section .header-left {
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width: 1919.98px) {
  .header-section .header-left .header-text {
    margin-left: 48px;
  }
}

.header-section .header-left .header-text h1 {
  font-family: "SailecLight", "OpenSans", sans-serif !important;
  margin-bottom: 48px;
  font-size: 82px;
  line-height: 115px;
  letter-spacing: 6px;
  font-weight: 700;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  .header-section .header-left .header-text h1 {
    font-size: 80px;
    margin-bottom: 24px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .header-section .header-left .header-text h1 {
    font-size: 56px;
    line-height: 84px;
    margin-bottom: 24px;
  }
}

@media (min-width: 480px) and (max-width: 767.98px) {
  .header-section .header-left .header-text h1 {
    font-size: 52px;
    line-height: 68px;
    margin: 32px 0 12px;
    text-align: center;
  }
}

@media (max-width: 479.98px) {
  .header-section .header-left .header-text h1 {
    font-size: 48px;
    line-height: 64px;
    margin: 24px 0 12px;
    text-align: center;
  }
}

#section-01 .text-section h2,
.header-section .header-left .header-text h1 span {
  font-family: "Sailec", "OpenSans", sans-serif !important;
}

.header-section .header-left .header-text h2 {
  font-family: "SailecLight", "OpenSans", sans-serif !important;
  font-size: 24px;
  font-weight: 100;
  margin: 64px 0 48px;
}

.header-section .header-left .header-text h2 span {
  white-space: nowrap;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  .header-section .header-left .header-text h2 {
    margin: 12px 0 24px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .header-section .header-left .header-text h2 {
    margin: 6px 0 12px;
  }
}

@media (min-width: 480px) and (max-width: 767.98px) {
  .header-section .header-left .header-text h2 {
    margin: 6px 0 12px;
    font-size: 28px;
    text-align: center;
  }
}

@media (max-width: 479.98px) {
  .header-section .header-left .header-text h2 {
    margin: 6px 0 12px;
    font-size: 24px;
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  .header-section .header-left .header-text .btn {
    display: none;
  }
}

#section-01,
.header-section .header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-section .header-right {
  width: 50%;
  -ms-flex-line-pack: center;
  align-content: center;
}

@media (min-width: 768px) and (max-width: 1439.98px) {
  .header-section .header-right {
    width: 40%;
  }
}

@media (max-width: 767.98px) {
  .header-section .header-right {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.header-section .header-right img {
  max-width: 100%;
  max-height: 100%;
  padding-bottom: 12px;
}

@media (min-width: 480px) and (max-width: 767.98px) {
  .header-section .header-right img {
    max-height: 340px;
  }
}

@media (max-width: 479.98px) {
  .header-section .header-right img {
    max-height: 320px;
  }
}

.header-section .header-right .btn {
  display: none;
  min-width: 300px;
  background: rgba(255, 255, 255, 0.1);
}

@media (max-width: 767.98px) {
  .header-section .header-right .btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0 0 36px;
  }
}

#section-01 .text-section p,
#section-02 .text-section .text-content,
.section-title {
  font-family: "SailecLight", "OpenSans", sans-serif !important;
}

.section-title {
  font-weight: 100;
  color: #333;
  font-size: 36px;
  font-style: normal;
  line-height: 56px;
  margin: 24px 0;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .section-title {
    line-height: 42px;
  }
}

@media (max-width: 1023.98px) {
  .section-title {
    text-align: center;
  }
}

.section-title-cran {
  color: #c6005d;
}

.section-title-purple {
  color: #560184;
}

.section-title-blue {
  color: #2ab8df;
}

.section-title-orange {
  color: #e56b1d;
}

.section-title-black {
  color: #000;
}

.section-title-cool-grad,
.section-title-warm-grad {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-title-warm-grad {
  background: -webkit-linear-gradient(#fcd303, #ed8b00, #d01665);
  background-clip: border-box;
}

.section-title-cool-grad {
  background: var(--hm-1-vertical,
      linear-gradient(0deg, #6b2d8b 0%, #c31f64 100%));
  background-clip: text;
}

#section-01 {
  background-color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 48px 0;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-01 {
    padding: 36px 0;
  }
}

@media (max-width: 767.98px) {
  #section-01 {
    padding: 24px 12px 0;
  }
}

#section-01 .text-section p {
  font-size: 36px;
  margin-bottom: 12px;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-01 .text-section p {
    font-size: 32px;
  }
}

@media (max-width: 767.98px) {
  #section-01 .text-section p {
    font-size: 18px;
  }
}

#section-01 .text-section span {
  font-size: 5rem;
  font-family: "SailecLight", "OpenSans", sans-serif;
}

#section-01 .text-section h2 {
  font-size: 48px;
  margin: 0;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-01 .text-section h2 {
    font-size: 42px;
  }
}

@media (max-width: 767.98px) {
  #section-01 .text-section h2 {
    font-size: 26px;
    margin: 6px 0;
    line-height: 32px;
  }
}

#section-02,
#section-02 .text-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#section-02 {
  height: 100vh;
  background-image: url(../images/section_02_BG.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  #section-02 {
    height: 80vh;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-02 {
    height: 70vh;
    background-position-x: 16%;
  }
}

@media (max-width: 767.98px) {
  #section-02 {
    height: 60vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

#section-02 .text-section {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 60%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  padding: 0 10%;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(213, 0, 86, 0.6)),
      to(rgba(252, 211, 3, 0.6)));
  background: -webkit-linear-gradient(rgba(213, 0, 86, 0.6),
      rgba(252, 211, 3, 0.6));
  background: -o-linear-gradient(rgba(213, 0, 86, 0.6), rgba(252, 211, 3, 0.6));
  background: linear-gradient(rgba(213, 0, 86, 0.6), rgba(252, 211, 3, 0.6));
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-02 .text-section {
    padding: 0 24px;
  }
}

@media (max-width: 767.98px) {
  #section-02 .text-section {
    width: 100%;
    padding: 24px;
    height: unset;
  }
}

#section-02 .text-section .text-content {
  max-width: 600px;
  color: #fff;
}

#section-02 .text-section .text-content p {
  color: #fff;
  font-size: 26px;
  margin-bottom: 42px;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  #section-02 .text-section .text-content p {
    font-size: 22px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-02 .text-section .text-content p {
    font-size: 18px;
    line-height: 28px;
  }
}

@media (max-width: 767.98px) {
  #section-02 .text-section .text-content p {
    font-size: 16px;
    line-height: 24px;
  }
}

#section-02 .text-section .text-content h2 {
  font-size: 38px;
  margin-bottom: 24px;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  #section-02 .text-section .text-content h2 {
    font-size: 32px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-02 .text-section .text-content h2 {
    font-size: 28px;
  }
}

@media (max-width: 767.98px) {
  #section-02 .text-section .text-content h2 {
    font-size: 24px;
  }
}

#section-02 .text-section .text-content a,
#section-09 .text-section .text-content a {
  color: #fff;
  font-size: 18px;
}

#section-02-1 {
  padding: 48px;
  margin: 64px;
  max-width: 2040px;
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 26px;
}

@media (min-width: 1919.98px) {
  #section-02-1 {
    margin: 64px auto;
  }
}

@media (max-width: 1023.98px) {
  #section-02-1 {
    padding: 24px 24px 0 24px;
    margin: 24px 0 0 0;
    -webkit-box-shadow: unset;
    box-shadow: unset;
  }
}

#section-02-1 .section-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 42px 0;
}

@media (max-width: 1023.98px) {
  #section-02-1 .section-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

#section-02-1 .section-col .half-col {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  padding: 24px;
  background-color: #fff;
  border-radius: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#section-02-1 .section-col .half-col .half-col--img img {
  max-width: 100%;
}

#section-02-1 .section-col .half-col .half-col--sub-title {
  font-size: 18px;
}

@media (max-width: 479.98px) {
  #section-02-1 .section-col .half-col .half-col--sub-title {
    font-size: 16px;
  }
}

#section-02-1 .section-col .half-col--center {
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 700px;
}

#section-02-1 .section-col .half-col--shadow {
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  margin: 12px;
}

@media (max-width: 767.98px) {
  #section-02-1 .section-col .half-col--shadow {
    margin: 16px 0 0;
  }
}

#section-02-1 .text-section p {
  max-width: 1024px;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-02-1 .text-section .section-title {
    font-size: 36px;
  }
}

@media (min-width: 480px) and (max-width: 767.98px) {
  #section-02-1 .text-section .section-title {
    font-size: 32px;
  }
}

@media (max-width: 479.98px) {
  #section-02-1 .text-section .section-title {
    font-size: 28px;
    line-height: 34px;
  }
}

#section-02-1 .md-right-img,
#section-02-1 .sm-right-img {
  max-width: 360px;
  -o-object-fit: contain;
  object-fit: contain;
  -ms-flex-item-align: center;
  align-self: center;
}

#section-02-1 .md-right-img {
  max-width: 85%;
}

.section-2-card {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media (max-width: 1023.98px) {
  .section-2-card {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    flex: 1 1 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media (max-width: 1023.98px) {
  .section-2-desktop-img {
    display: none;
  }
}

@media (min-width: 1023.98px) {
  .section-2-mob-img {
    display: none;
  }
}

@media (min-width: 1023.98px) {
  #section-02-last-text {
    padding-left: 0 !important;
  }
}

@media (max-width: 1023.98px) {
  #section-02-last-text {
    order: 2;
  }

  #section-02-last-img {
    order: 1;
  }
}

#section-03 .flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#section-03 .column-half {
  position: relative;
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 767.98px) {
  #section-03 .column-half {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

#section-03 .btn {
  margin: 48px auto;
}

#section-04 {
  margin: 0 0 64px;
}

#section-04 .text-section,
#section-08-2 h2 {
  text-align: center;
}

#section-04 .text-section h2 {
  color: #2397b9;
  font-size: 64px;
  line-height: 2;
  margin-bottom: 128px;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-04 .text-section h2 {
    font-size: 36px;
    margin-bottom: 64px;
  }
}

@media (min-width: 480px) and (max-width: 767.98px) {
  #section-04 .text-section h2 {
    font-size: 32px;
    margin-bottom: 32px;
    line-height: 1.5;
  }
}

@media (max-width: 479.98px) {
  #section-04 .text-section h2 {
    font-size: 28px;
    margin-bottom: 24px;
    line-height: 1.5;
  }
}

#section-04 .text-section p {
  margin: 64px 0;
  font-size: 48px;
  font-weight: 100;
  color: #535353;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-04 .text-section p {
    font-size: 36px;
    margin: 48px 0;
  }
}

@media (min-width: 480px) and (max-width: 767.98px) {
  #section-04 .text-section p {
    font-size: 32px;
    margin: 36px 0;
  }
}

@media (max-width: 479.98px) {
  #section-04 .text-section p {
    font-size: 28px;
    margin: 32px 0;
  }
}

#section-04 .text-section hr {
  max-width: 75%;
  margin: 0 auto;
  color: #535353;
}

#section-05 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(15.1%, #6b2d8b),
      to(#2ab8df));
  background: -webkit-linear-gradient(bottom, #6b2d8b 15.1%, #2ab8df 100%);
  background: -o-linear-gradient(bottom, #6b2d8b 15.1%, #2ab8df 100%);
  background: linear-gradient(360deg, #6b2d8b 15.1%, #2ab8df 100%);
  padding: 48px 0;
}

@media (max-width: 767.98px) {
  #section-05 {
    padding: 24px 0;
  }
}

#section-05 .text-section p {
  font-size: 42px;
  line-height: 52px;
  text-align: center;
  color: #fff;
  margin-bottom: 64px;
  margin-top: 72px;
}

@media (min-width: 480px) and (max-width: 767.98px) {
  #section-05 .text-section p {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 32px;
    margin-top: 32px;
  }
}

@media (max-width: 479.98px) {
  #section-05 .text-section p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;
    margin-top: 24px;
  }
}

#section-05 #flag-01 {
  position: relative;
  right: -100%;
  max-width: 500px;
  display: none;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-05 #flag-01 {
    max-width: 250px;
  }
}

@media (max-width: 767.98px) {
  #section-05 #flag-01 {
    max-width: 180px;
  }
}

#section-05 #flag-02 {
  position: relative;
  -webkit-transform: translate(-100%);
  -o-transform: translate(-100%);
  transform: translate(-100%);
  max-width: 500px;
  display: none;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-05 #flag-02 {
    max-width: 250px;
  }
}

@media (max-width: 767.98px) {
  #section-05 #flag-02 {
    max-width: 180px;
  }
}

#section-06 .section-title {
  font-size: 52px;
  margin-top: 120px;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  #section-06 .section-title {
    font-size: 48px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-06 .section-title {
    font-size: 36px;
    margin-top: 60px;
  }
}

@media (min-width: 480px) and (max-width: 767.98px) {
  #section-06 .section-title {
    font-size: 32px;
    margin-top: 60px;
  }
}

@media (max-width: 479.98px) {
  #section-06 .section-title {
    font-size: 28px;
    margin-top: 40px;
  }
}

#section-07 .logo-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -ms-flex-line-pack: center;
  align-content: center;
  height: 36px;
  margin: 64px 0;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  #section-07 .logo-section {
    height: unset;
  }
}

@media (max-width: 1023.98px) {
  #section-07 .logo-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: unset;
    max-width: 300px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
  }
}

#section-07 .logo-section img {
  -o-object-fit: contain;
  object-fit: contain;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  #section-07 .logo-section img {
    max-width: 120px;
  }
}

@media (max-width: 1023.98px) {
  #section-07 .logo-section img {
    margin: 16px 0;
  }
}

#section-08 .slide-in-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 760px;
}

@media (max-width: 1023.98px) {
  #section-08 .slide-in-wrap {
    min-height: unset;
  }
}

@media (max-width: 767.98px) {
  #section-08 .slide-in-wrap {
    padding: 0 12px;
  }
}

#section-08 .slide-in-wrap .slide-in-img-wrap {
  width: 44%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767.98px) {
  #section-08 .slide-in-wrap .slide-in-img-wrap {
    display: none;
  }
}

#section-08 .slide-in-wrap .slide-in-img-wrap .slide-in-img-static,
#section-08 .slide-in-wrap .slide-in-txt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#section-08 .slide-in-wrap .slide-in-img-wrap .slide-in-img-static {
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  #section-08 .slide-in-wrap .slide-in-img-wrap .slide-in-img-static img {
    max-width: 835px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-08 .slide-in-wrap .slide-in-img-wrap .slide-in-img-static img {
    max-width: 535px;
  }
}

#section-08 .slide-in-wrap .slide-in-txt {
  width: 56%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 767.98px) {
  #section-08 .slide-in-wrap .slide-in-txt {
    width: 100%;
    opacity: 1 !important;
    -webkit-transform: translate(0) !important;
    -o-transform: translate(0) !important;
    transform: translate(0) !important;
    margin: 24px 0;
  }
}

#section-08 .slide-in-wrap .slide-in-txt .slide-in-txt-section {
  max-width: 780px;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  #section-08 .slide-in-wrap .slide-in-txt .slide-in-txt-section {
    padding: 0 24px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-08 .slide-in-wrap .slide-in-txt .slide-in-txt-section {
    padding: 0 12px;
  }
}

#section-08 .slide-in-wrap .slide-in-txt .slide-in-txt-section .section-title {
  font-size: 48px;
  font-family: "Sailec", "OpenSans", sans-serif !important;
  line-height: 68px;
  margin-bottom: 42px;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  #section-08 .slide-in-wrap .slide-in-txt .slide-in-txt-section .section-title {
    font-size: 32px;
    line-height: 48px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-08 .slide-in-wrap .slide-in-txt .slide-in-txt-section .section-title {
    font-size: 28px;
    line-height: 38px;
  }
}

@media (max-width: 767.98px) {
  #section-08 .slide-in-wrap .slide-in-txt .slide-in-txt-section .section-title {
    text-align: center;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 0;
  }
}

#section-08 .slide-in-wrap .slide-in-txt .slide-in-txt-section p {
  font-size: 36px;
  line-height: 58px;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  #section-08 .slide-in-wrap .slide-in-txt .slide-in-txt-section p {
    font-size: 28px;
    line-height: 38px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-08 .slide-in-wrap .slide-in-txt .slide-in-txt-section p {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 767.98px) {
  #section-08 .slide-in-wrap .slide-in-txt .slide-in-txt-section p {
    text-align: center;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 36px;
  }
}

#section-08 .slide-in-wrap .slide-in-txt .slide-in-txt-section .slide-in-mob-img {
  -webkit-transform: translate(0);
  -o-transform: translate(0);
  transform: translate(0);
  max-width: 100%;
}

@media (min-width: 768px) {
  #section-08 .slide-in-wrap .slide-in-txt .slide-in-txt-section .slide-in-mob-img {
    display: none;
  }
}

#section-08 .slide-in-left img {
  -webkit-transform: translate(-100%);
  -o-transform: translate(-100%);
  transform: translate(-100%);
}

#section-08 .slide-in-right .slide-in-txt {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

#section-08 .slide-in-right img {
  -webkit-transform: translate(200%);
  -o-transform: translate(200%);
  transform: translate(200%);
}

#section-08-2 {
  margin-top: 128px;
}

@media (min-width: 480px) and (max-width: 767.98px) {
  #section-08-2 {
    margin-top: 48px;
  }
}

@media (max-width: 479.98px) {
  #section-08-2 {
    margin-top: 32px;
  }
}

#section-08-2 h2 {
  font-size: 64px;
  margin: 24px;
}

@media (min-width: 480px) and (max-width: 767.98px) {
  #section-08-2 h2 {
    font-size: 38px;
  }
}

@media (max-width: 479.98px) {
  #section-08-2 h2 {
    font-size: 32px;
  }
}

#section-08-2 .responsive-two-column-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

@media (max-width: 767.98px) {
  #section-08-2 .responsive-two-column-grid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
}

#section-08-2 .responsive-two-column-grid .half-col {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  padding: 24px;
  max-width: 740px;
}

@media (max-width: 767.98px) {
  #section-08-2 .responsive-two-column-grid .half-col {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 480px;
  }
}

#section-08-2 .responsive-two-column-grid .half-col ul {
  margin: 0 0 24px 24px;
}

#section-08-2 .responsive-two-column-grid .half-col ul li {
  line-height: 42px;
}

@media (max-width: 479.98px) {
  #section-08-2 .responsive-two-column-grid .half-col ul li {
    font-size: 18px;
    line-height: 1.75;
  }
}

#section-08-2 .responsive-two-column-grid .half-col strong {
  font-family: "Sailec", "OpenSans", sans-serif;
}

@media (max-width: 767.98px) {
  #topics-index {
    margin-left: 24px;
    margin-right: 24px;
  }
}

#section-08-2 .text-purple {
  color: #560184;
}

@media (max-width: 479.98px) {
  #section-08-2 .text-purple {
    font-size: 18px;
  }
}

#section-09,
#section-09 .text-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#section-09 {
  height: 100vh;
  background-image: url(../images/section_09_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-position: left 75%;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  #section-09 {
    height: 80vh;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-09 {
    height: 70vh;
  }
}

@media (max-width: 767.98px) {
  #section-09 {
    height: 50vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

#section-09 .text-section {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 60%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  padding: 0 10%;
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(15.1%, rgba(107, 45, 139, 0.6)),
      to(rgba(42, 184, 223, 0.6)));
  background: -webkit-linear-gradient(bottom,
      rgba(107, 45, 139, 0.6) 15.1%,
      rgba(42, 184, 223, 0.6) 100%);
  background: -o-linear-gradient(bottom,
      rgba(107, 45, 139, 0.6) 15.1%,
      rgba(42, 184, 223, 0.6) 100%);
  background: linear-gradient(360deg,
      rgba(107, 45, 139, 0.6) 15.1%,
      rgba(42, 184, 223, 0.6) 100%);
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-09 .text-section {
    padding: 0 24px;
  }
}

@media (max-width: 767.98px) {
  #section-09 .text-section {
    width: 100%;
    padding: 24px;
    height: unset;
  }
}

#section-09 .text-section .text-content {
  max-width: 600px;
  color: #fff;
  font-family: "SailecLight", "OpenSans", sans-serif !important;
}

#section-09 .text-section .text-content p {
  color: #fff;
  font-size: 26px;
  margin-bottom: 42px;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  #section-09 .text-section .text-content p {
    font-size: 22px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-09 .text-section .text-content p {
    font-size: 18px;
    line-height: 28px;
  }
}

@media (max-width: 767.98px) {
  #section-09 .text-section .text-content p {
    font-size: 16px;
    line-height: 24px;
  }
}

#section-09 .text-section .text-content h2 {
  font-size: 58px;
  line-height: 1.5;
  margin-bottom: 24px;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  #section-09 .text-section .text-content h2 {
    font-size: 52px;
    line-height: 1.25;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-09 .text-section .text-content h2 {
    font-size: 38px;
  }
}

@media (max-width: 767.98px) {
  #section-09 .text-section .text-content h2 {
    font-size: 24px;
  }
}

#section-10,
#section-10 .three-up-cards .three-up-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#section-10 {
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(15.1%, #6b2d8b),
      to(#2ab8df));
  background: -webkit-linear-gradient(bottom, #6b2d8b 15.1%, #2ab8df 100%);
  background: -o-linear-gradient(bottom, #6b2d8b 15.1%, #2ab8df 100%);
  background: linear-gradient(360deg, #6b2d8b 15.1%, #2ab8df 100%);
  padding-bottom: 84px;
}

@media (max-width: 767.98px) {
  #section-10 {
    padding: 0 12px;
  }
}

#section-10 .three-up-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  margin: 48px;
}

@media (max-width: 767.98px) {
  #section-10 .three-up-cards {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 24px;
  }
}

#section-10 .three-up-cards .three-up-card {
  width: 33.3%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3%;
  flex: 0 0 33.3%;
  max-width: 540px;
  padding: 48px 24px;
  margin: 0 12px;
  background-color: rgba(255, 255, 255, 0.09);
  border-radius: 24px;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-10 .three-up-cards .three-up-card {
    padding: 24px 12px;
  }
}

@media (max-width: 767.98px) {
  #section-10 .three-up-cards .three-up-card {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin: 12px 0;
    padding: 24px 12px;
  }
}

#section-10 .three-up-cards .three-up-card img {
  max-width: 100%;
  max-height: 100%;
  -ms-flex-item-align: center;
  align-self: center;
}

@media (max-width: 767.98px) {
  #section-10 .three-up-cards .three-up-card img {
    max-width: 90%;
    max-height: 90%;
  }
}

#section-10 .three-up-cards .three-up-card .three-up-card--text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#section-10 .three-up-cards .three-up-card .three-up-card--title {
  color: #fff;
  margin: 24px 0 12px;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-10 .three-up-cards .three-up-card .three-up-card--title {
    font-size: 18px;
  }
}

@media (max-width: 767.98px) {
  #section-10 .three-up-cards .three-up-card .three-up-card--title {
    font-size: 18px;
    text-align: center;
  }
}

#section-10 .three-up-cards .three-up-card .three-up-card--subtitle {
  color: #fff;
  font-weight: 200;
  font-family: "SailecLight", "OpenSans", sans-serif;
  font-size: 22px;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  #section-10 .three-up-cards .three-up-card .three-up-card--subtitle {
    font-size: 16px;
  }
}

@media (max-width: 767.98px) {
  #section-10 .three-up-cards .three-up-card .three-up-card--subtitle {
    font-size: 16px;
    text-align: center;
  }
}

#section-10 .three-up-cards .three-up-card .three-up-card--subtitle-left {
  text-align: left !important;
}

#section-10 .three-up-cards .three-up-card .three-up-card--citation {
  color: #fff;
  font-weight: 100;
  font-family: "SailecLight", "OpenSans", sans-serif;
  font-size: 24px;
  margin-top: 64px;
}

@media (max-width: 767.98px) {
  #section-10 .three-up-cards .three-up-card .three-up-card--citation {
    margin-top: 48px;
    font-size: 18px;
  }
}

#section-10 .three-up-cards .three-up-testimonial {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#section-10 .divider-section,
#section-10 .store-logos-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#section-10 .store-logos-section {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#section-10 .store-logos-section .store-logos-section--title {
  margin: 48px 0;
  color: #fff;
}

@media (max-width: 767.98px) {
  #section-10 .store-logos-section .store-logos-section--title {
    text-align: center;
    font-size: 22px;
  }
}

#section-10 .store-logos-section .store-logos-section--apps {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 640px;
}

@media (max-width: 767.98px) {
  #section-10 .store-logos-section .store-logos-section--apps {
    min-width: unset;
    width: 100%;
  }
}

#section-10 .store-logos-section .store-logos-section--hm-apps {
  -ms-flex-pack: distribute;
  justify-content: space-around;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 420px;
}

@media (max-width: 767.98px) {
  #section-10 .store-logos-section .store-logos-section--hm-apps {
    min-width: unset;
    width: 100%;
  }
}

#section-10 .store-logos-section .store-logos-section--hm-apps img {
  max-height: 64px;
}

@media (max-width: 479.98px) {
  #section-10 .store-logos-section .store-logos-section--hm-apps img {
    max-height: 48px;
  }
}

#section-10 .divider-section {
  margin: 64px 0;
}

@media (max-width: 767.98px) {
  #section-10 .divider-section {
    margin: 24px 0;
  }
}

.footer-section,
.footer-section .footer-left-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-section {
  padding: 24px 12px;
}

@media (max-width: 767.98px) {
  .footer-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 56px;
  }
}

.footer-section .footer-left-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 30%;
}

@media (max-width: 767.98px) {
  .footer-section .footer-left-col {
    width: 100%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}

.footer-section .footer-left-col img {
  max-width: 240px;
}

.footer-section .footer-left-col .footer-copyright {
  margin: 12px;
  font-size: 16px;
  text-align: center;
}

.footer-section .footer-right-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-item-align: center;
  align-self: center;
  width: 70%;
}

@media (max-width: 767.98px) {
  .footer-section .footer-right-col {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.footer-section .footer-right-col .footer-links {
  justify-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

@media (max-width: 767.98px) {
  .footer-section .footer-right-col .footer-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.footer-section .footer-right-col .footer-links a {
  color: #535353;
  font-size: 16px;
  text-align: center;
  padding: 0 24px;
}

.container-fluid a:after,
.footer-section .footer-right-col .footer-links a:after,
.message .message-txt a::after {
  content: "";
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .footer-section .footer-right-col .footer-links a {
    padding: 0 6px;
  }
}

@media (max-width: 767.98px) {
  .footer-section .footer-right-col .footer-links a {
    padding: 0;
    margin: 8px 0;
  }
}

.sticky-nav {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 5;
  height: 52px;
  background: #f2f2f2c4;
  display: block;
  border-top: 2px solid #fff;
}

@media (min-width: 480px) {
  .sticky-nav {
    display: none;
  }
}

.sticky-nav .sticky-nav--btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.sticky-nav .sticky-nav--btn .btn {
  margin-top: 6px !important;
  padding: 0.75rem 3rem !important;
  -webkit-box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.4);
}

:root {
  font-size: 10px;
}

body,
html {
  height: 100%;
}

body {
  overflow-x: hidden;
  background: #fff;
  font-family: "Sailec", "OpenSans", sans-serif;
  font-size: 24px;
  background-color: #fff;
  color: #000;
  margin: 0 auto;
}

p:empty {
  display: none;
}

.btn,
.btn-primary:hover {
  color: #fff;
  text-decoration: none;
}

.btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-family: inherit;
  font-size: 1.5rem;
  text-align: center;
  font-weight: inherit;
  text-transform: capitalize;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin-top: 24px;
}

.btn::after {
  content: "";
  margin: 0;
}

.btn-primary {
  background-color: #3ab8f4;
  border: 0;
  border-radius: 39px;
  padding: 1.25rem 3rem;
}

.btn-primary:hover {
  background-color: #0079b1;
}

.btn-secondary {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.btn-quaternary:hover,
.btn-secondary:hover,
.btn-tertiary:hover {
  background-color: #1ab8f5;
  color: #fff;
  text-decoration: none;
}

.btn-quaternary,
.btn-secondary,
.btn-tertiary {
  border-radius: 39px;
  padding: 1.25rem 3rem;
}

.btn-tertiary {
  color: #3ab8f4;
  border: 1px solid #3ab8f4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 24px;
}

.btn-quaternary {
  background-color: #0079b1;
  color: #fff;
  border: 2px solid #fff;
}

.btn-quaternary:hover {
  background-color: #3ab8f4;
}

.gradient-btn {
  background: -webkit-linear-gradient(315deg,
      #742b86 0,
      #9d2574 50%,
      #bb2067 100%);
  background: -o-linear-gradient(315deg, #742b86 0, #9d2574 50%, #bb2067 100%);
  background: linear-gradient(135deg, #742b86 0, #9d2574 50%, #bb2067 100%);
  letter-spacing: 2px;
  padding: 1rem 3rem;
}

.gradient-btn:hover {
  background: #444;
  color: #fff;
  text-decoration: none;
}

.gradient-btn-secondary {
  background: -webkit-linear-gradient(315deg,
      #560184 0,
      #9d2574 50%,
      #bb2067 100%);
  background: -o-linear-gradient(315deg, #560184 0, #9d2574 50%, #bb2067 100%);
  background: linear-gradient(135deg, #560184 0, #9d2574 50%, #bb2067 100%);
  border-radius: 0.25rem;
  padding: 1rem 3rem;
}

.gradient-btn-secondary:hover {
  background: -webkit-linear-gradient(315deg,
      #bb2067 0,
      #9d2574 50%,
      #560184 100%);
  background: -o-linear-gradient(315deg, #bb2067 0, #9d2574 50%, #560184 100%);
  background: linear-gradient(135deg, #bb2067 0, #9d2574 50%, #560184 100%);
  border-color: #560184;
  color: #fff;
  text-decoration: none;
}

.content-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.content-container>* {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.contentMarker {
  min-height: 100vh;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0 24px 0 0;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .contentMarker {
    padding-left: 0;
  }
}

@media (max-width: 767.98px) {
  .contentMarker {
    min-height: unset;
    padding: 0 12px;
    margin: 42px 0;
  }
}

.contentMarker .content-section {
  background: #fff;
  border-radius: 26px;
  border: 1px solid #eaeaea;
  padding: 24px;
}

.contentMarker .content-section .section-title {
  margin-bottom: 32px;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .contentMarker .content-section .section-title {
    font-size: 28px;
    margin-bottom: 12px;
  }
}

@media (max-width: 767.98px) {
  .contentMarker .content-section .section-title {
    font-size: 28px;
    text-align: center;
    margin: 36px 0 12px;
    line-height: 46px;
  }
}

.contentMarker .content-section .content-section--img-lg {
  display: block;
  margin: 32px 0;
  max-width: 580px;
}

@media (max-width: 767.98px) {
  .contentMarker .content-section .content-section--img-lg {
    margin: 32px auto;
  }
}

.contentMarker .content-section .content-section--blurb {
  max-width: 580px;
}

@media (max-width: 767.98px) {
  .contentMarker .content-section .content-section--blurb {
    max-width: unset;
    margin: 0 auto;
  }
}

.contentMarker .content-section .content-section--img-card {
  margin-bottom: 12px;
}

.contentMarker .content-section img {
  max-width: 100%;
  width: 100%;
}

.contentMarker .content-section p {
  font-family: "SailecLight", "OpenSans", sans-serif !important;
  color: #333;
  font-size: 32px;
  line-height: 56px;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .contentMarker .content-section p {
    font-size: 20px;
    line-height: 42px;
  }
}

@media (max-width: 767.98px) {
  .contentMarker .content-section p {
    line-height: 36px;
    font-size: 24px;
  }
}

.contentMarker .content-section a {
  margin-top: 48px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.contentMarker .content-section .content-section-img-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 32px 0;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .contentMarker .content-section .content-section-img-wrap {
    margin: 16px 0;
  }
}

@media (max-width: 767.98px) {
  .contentMarker .content-section .content-section-img-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.contentMarker .content-section .content-section-img-wrap img,
.contentMarker .content-section a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.contentMarker .content-section .content-section-img-wrap .content-section-col,
.contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card--inner {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contentMarker .content-section .content-section-img-wrap .content-section-col {
  width: 33.3%;
}

@media (max-width: 767.98px) {
  .contentMarker .content-section .content-section-img-wrap .content-section-col {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
  }
}

.contentMarker .content-section .content-section-img-wrap .content-section-col p {
  font-size: 16px;
}

.contentMarker .content-section .content-section-img-wrap .content-section-col span {
  padding: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #333;
  text-align: center;
  font-size: 12px;
  font-family: "SailecLight", "OpenSans", sans-serif !important;
  font-weight: 400;
  margin-top: 6px;
}

.contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card--inner {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
  border-radius: 24px;
  max-width: 90%;
  min-height: 175px;
  padding: 6px;
  margin-bottom: 24px;
}

@media (min-width: 1024px) and (max-width: 1439.98px) {
  .contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card--inner {
    min-height: 125px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card--inner {
    min-height: 125px;
  }
}

@media (max-width: 767.98px) {
  .contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card--inner {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 50%;
    flex: 0 1 50%;
    margin: 12px;
    min-height: unset;
  }
}

.contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card--inner .card--inner-p {
  line-height: 36px;
  font-size: 24px;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card--inner .card--inner-p {
    line-height: 26px;
    font-size: 14px;
  }
}

@media (max-width: 767.98px) {
  .contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card--inner .card--inner-p {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;
  }
}

.contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card--inner .card--inner-author {
  -ms-flex-item-align: end;
  align-self: flex-end;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card--inner .card--inner-author img {
  width: unset;
}

.contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card--inner .card--inner-author .card--inner-author-text {
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 16px;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card--inner .card--inner-author .card--inner-author-text {
    font-size: 14px;
  }
}

.contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card--inner-full {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 !important;
  flex: 1 !important;
}

.contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card-promo {
  padding: 24px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card-promo {
    padding: 8px;
  }
}

@media (max-width: 767.98px) {
  .contentMarker .content-section .content-section-img-wrap .content-section-col .content-section-col--card-promo {
    width: 100%;
    padding: 16px;
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 !important;
    flex: 1 !important;
  }

  .contentMarker .content-section .content-section-img-wrap .content-section-col--card {
    max-width: 320px;
  }
}

.contentMarker .content-section .content-section-img-wrap .content-section-col-full {
  width: 100% !important;
  padding: 16px !important;
}

@media (max-width: 767.98px) {
  .contentMarker .content-section .content-section-img-wrap .content-section-col-full {
    padding: 0 !important;
    margin-top: -24px;
  }
}

.contentMarker .content-section .content-section-img-wrap--centered .content-section-col--card {
  width: 30%;
  padding: 6px 6px 24px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
  border-radius: 24px;
}

@media (max-width: 767.98px) {
  .contentMarker .content-section .content-section-img-wrap--centered .content-section-col--card {
    width: 275px;
    margin-bottom: 24px;
  }
}

.contentMarker .content-section .content-section-img-wrap--centered .content-section-col--card .content-section-col--card--inner {
  border-radius: 26px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}

.contentMarker .content-section .content-section-img-wrap--centered .content-section-col--card .content-section-col--card--inner-bio .profile-pic {
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 18px;
  margin: 0 0 12px;
}

.contentMarker .content-section .content-section-img-wrap--centered .content-section-col-half {
  width: 50%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 767.98px) {
  .contentMarker .content-section .content-section-img-wrap--centered .content-section-col-half {
    width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 767.98px) {
  .contentMarker .content-section .content-section-bios {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.contentMarker .content-section .content-section-bios .content-section-bio-card {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

@media (min-width: 480px) and (max-width: 767.98px) {
  .contentMarker .content-section .content-section-bios .content-section-bio-card {
    width: 250px;
    margin: 12px 0;
  }
}

.contentMarker .content-section .bio-text {
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  height: 100%;
  padding: 24px 0;
}

.left-content,
.right-content {
  position: relative;
}

.left-content {
  height: 100vh !important;
  max-height: unset !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.left-content img {
  position: absolute;
  max-width: 156%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  opacity: 0;
  visibility: hidden;
}

@media screen and (min-width: 1920px) {
  .left-content img {
    left: 5%;
    max-height: 110%;
  }
}

.left-content #img1 {
  opacity: 1;
  visibility: visible;
  -webkit-transform-origin: 50% 50% 0;
  -o-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0;
}

@media (max-width: 767.98px) {
  #pinned-left-col {
    display: none;
  }

  #pinned-right-col {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .mob-img {
    display: none;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .carousel-inner .active.col-md-4.carousel-item+.carousel-item+.carousel-item+.carousel-item {
    position: absolute;
    top: 0;
    right: -33.3333%;
    z-index: -1;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .carousel-inner .active.col-sm-6.carousel-item+.carousel-item+.carousel-item {
    position: absolute;
    top: 0;
    right: -50%;
    z-index: -1;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 480px) {
  .carousel-item {
    margin-right: 0;
  }

  .carousel-inner .active+.carousel-item {
    display: block;
  }

  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }

  .carousel-inner .carousel-item-next {
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .active.carousel-item-left+.carousel-item-next.carousel-item-left,
  .carousel-item-next.carousel-item-left+.carousel-item,
  .carousel-item-next.carousel-item-left+.carousel-item+.carousel-item {
    position: relative;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }

  .active.carousel-item-right+.carousel-item-prev.carousel-item-right,
  .carousel-item-prev.carousel-item-right+.carousel-item,
  .carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item {
    position: relative;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
  }
}

@media (min-width: 768px) {
  .carousel-inner .active+.carousel-item+.carousel-item {
    display: block;
  }

  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item+.carousel-item {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }

  .carousel-inner .carousel-item-next {
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .carousel-item-next.carousel-item-left+.carousel-item+.carousel-item+.carousel-item {
    position: relative;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  .carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item+.carousel-item {
    position: relative;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
  }
}

@media (min-width: 1024px) {
  .carousel-inner .active+.carousel-item+.carousel-item+.carousel-item {
    display: block;
  }

  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item+.carousel-item+.carousel-item {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }

  .carousel-inner .active.col-lg-3.carousel-item+.carousel-item+.carousel-item+.carousel-item+.carousel-item {
    position: absolute;
    top: 0;
    right: -25%;
    z-index: -1;
    display: block;
    visibility: visible;
  }

  .carousel-item-next.carousel-item-left+.carousel-item+.carousel-item+.carousel-item+.carousel-item {
    position: relative;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  .carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item+.carousel-item+.carousel-item {
    position: relative;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
  }
}

.top-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 72px 0;
}

@media (max-width: 767.98px) {
  .top-content {
    padding: 0;
  }
}

.carousel-item .slide-txt {
  display: none;
}

.modalOverlayNew {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  text-align: center;
  z-index: 11;
  cursor: pointer;
  opacity: 0;
}

.modalContainer {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.message,
.messageWrapper,
.modalContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.messageWrapper {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100vw;
  max-width: 1088px;
  background-color: #fff;
  position: absolute;
  z-index: 12;
  opacity: 0;
  border-radius: 26px;
  padding: 2px;
}

.message {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-item img,
.message .message-img {
  border-radius: 26px;
}

@media (max-width: 767.98px) {
  .message .message-img {
    display: none !important;
  }
}

.message .message-txt {
  overflow-y: auto;
  margin-right: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 24px;
}

@media (max-width: 767.98px) {
  .message .message-txt {
    padding: 12px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.message .message-txt p {
  font-size: 22px;
  line-height: 40px;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .message .message-txt p {
    font-size: 18px;
    line-height: 32px;
  }
}

@media (min-width: 480px) and (max-width: 767.98px) {
  .message .message-txt p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 479.98px) {
  .message .message-txt p {
    font-size: 16px;
    line-height: 28px;
  }
}

.message .message-txt li {
  font-size: 16px;
  line-height: 24px;
}

.message .message-txt .slide-txt-long {
  margin-top: 24px;
}

@media (max-width: 1023.98px) {
  .message .message-txt .slide-txt-long {
    display: none;
  }
}

.section-title-warm-grad {
  background: -webkit-linear-gradient(#ed8b00, #d01665);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-title-cool-grad {
  background: var(--hm-1-vertical,
      linear-gradient(0deg, #6b2d8b 0%, #c31f64 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 767.98px) {
  .half-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 1023.98px) {
  .mobile-hide {
    display: none !important;
  }
}

@media (max-width: 480px) {
  #section-02-1, #section-04 {
    margin-top: 0 !important;
  }

  #section-02 .btn {
    display: none !important;
  }

  #section-09 .btn {
    display: none !important;
  }

  .text-section {
    padding: 12px !important;
  }

  #section-02 .text-section .text-content p {
    margin-bottom: 0;
  }

  #section-01 .text-section span {
    font-size: 3.5rem;
  }
}